<template>
  <div>
    <ds-header title="服务手册查看"></ds-header>
    <a-form-model
      ref="ruleForm"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item label="目录名称">
        {{ form.title }}
      </a-form-model-item>
      <a-form-model-item label="所属目录">
        {{ form.belongTitle }}
      </a-form-model-item>
      <a-form-model-item label="优先级">
        {{ form.sortNum }}
      </a-form-model-item>
      <a-form-model-item label="当前所在手册">
        {{ form.handBookName }}
      </a-form-model-item>
      <a-form-model-item label="内容">
        <div class="upload-class">
          <a-upload-dragger
            name="file"
            :multiple="true"
            :action="IMG_API + '/oss/files'"
            @change="handleChange"
            :before-upload="beforeUpload"
            :showUploadList="false"
          >
            <p class="ant-upload-drag-icon">
              <a-icon type="inbox" />
            </p>
            <p class="ant-upload-text">
              将文件拖到此处，或<a>点击上传</a>
            </p>
          </a-upload-dragger>
        </div>
        <span style="color:red">只能上传jpg/png文件，且不超过500KB</span>
        <a-table :pagination="false" pagind :columns="columns" :data-source="data" bordered>
          <div slot="content" slot-scope="text">
            <div class="clearfix">
              <a-upload
                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                list-type="picture-card"
                :showUploadList="{ showRemoveIcon: false }"
                :file-list="[{
                  uid: '-1',
                  name: 'image.png',
                  status: 'done',
                  url: text,
                }]"
                @preview="handlePreview"
              >
              </a-upload>
              <a-modal :visible="previewVisible" :footer="null"  @cancel="handleCancel">
                <img alt="example" style="width: 100%" :src="previewImage" />
              </a-modal>
            </div>
          </div>
          <div slot="operation" slot-scope="text, record, index">
            <span v-show="index !== 0">
              <a @click="upClick(index)">上移</a>
              <a-divider type="vertical" />
            </span>
            <span v-show="index !== data.length - 1">
              <a @click="downClick(index)">下移</a>
              <a-divider type="vertical" />
            </span>
            <a @click="removeClick(index)">删除</a>
          </div>
        </a-table>
      </a-form-model-item>
      <a-form-model-item label="" v-bind="tailFormItemLayout">
        <a-button type="primary" @click="toSave">保存</a-button>
        <a-divider type="vertical" />
        <a-button type="primary" @click="goBack">返回</a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>
<script>
import { findOneServiceManualById, serviceManualConfig } from '@/api/serviceManualManagement'
import { IMG_API } from "@/config";
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

const columns = [
  {
    title: '排序',
    dataIndex: 'sortNum',
    key: 'sortNum',
    width: 80,
    align: 'center'
  },
  {
    title: '内容',
    dataIndex: 'content',
    width: 80,
    align: 'center',
    scopedSlots: { customRender: 'content' },
  },
  {
    title: '操作',
    width: 80,
    scopedSlots: {customRender: 'operation'}
  }
]
export default {
  name: 'configServiceManual',
  data() {
    return {
      IMG_API,
      form: {},
      previewVisible: false,
      previewImage: '',
      labelCol: { span: 2 },
      wrapperCol: { span: 10 },
      tailFormItemLayout: {
        wrapperCol: {
          xs: {
            span: 18,
            offset: 0,
          },
          sm: {
            span: 10,
            offset: 6,
          },
        },
      },
      columns,
      data: [],
      fileList: []
    }
  },
  async created() {
    const id = this.$route.query.id
    await this.findOne(id)
  },
  methods: {
    async findOne(id) {
      const res = await findOneServiceManualById(id)
      const { code, data } = res
      if (code === '200') {
        this.form = data
        this.data = data.contents
      }
    },
    handleChange(info) {
      const status = info.file.status;
      if (status !== 'uploading') {
        // console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        console.log(info.file);
        const { response: { redirect_uri } } = info.file
        this.data.push({sortNum: this.data.length + 1, content: redirect_uri})
      } else if (status === 'error') {
        this.$message.error(`${info.file.name} file upload failed.`);
      }
    },
    // 上传图片前校验
    beforeUpload (file) {
      // 校验格式
      const isJpgOrPng = file.type === "image/jpeg"
        || file.type === "image/png"
        || file.type === "image/jpg";
      if (!isJpgOrPng) {
        this.$message.error("请上传jpg、或png格式");
        return Promise.reject();
      }
      // 校验大小
      const isLt2M = file.size / 1024 <= 500;
      if (!isLt2M) {
        this.$message.error("图片不能超过500KB");
        return Promise.reject();
      }
      return isJpgOrPng && isLt2M;
    },
    // 预览
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    // 取消预览
    handleCancel() {
      this.previewVisible = false;
    },
    // 上调
    upClick(index) {
      let newData = JSON.parse(JSON.stringify(this.data))
      const item = newData[index];
      newData.splice(index, 1)
      newData.splice(index - 1, 0 ,item)
      this.data = newData.map((item, index) => {
        return {
          sortNum: index + 1,
          content: item.content
        }
      });
    },
    // 下调
    downClick(index) {
      let newData = JSON.parse(JSON.stringify(this.data))
      const item = newData[index];
      newData.splice(index, 1)
      newData.splice(index + 1, 0 ,item)
      this.data = newData.map((item, index) => {
        return {
          sortNum: index + 1,
          content: item.content
        }
      });
    },
    // 删除
    removeClick(index) {
      let newData = JSON.parse(JSON.stringify(this.data))
      newData.splice(index, 1)
      this.data = newData.map((item, index) => {
        return {
          sortNum: index + 1,
          content: item.content
        }
      });
    },
    // 保存
    async toSave() {
      const params = {
        serviceBookId: this.form.id,
        contents: this.data
      }
      const res = await serviceManualConfig(params)
      const { code } = res
      if (code === '200') {
        this.$message.success('操作成功')
        this.goBack();
      }
    },
    goBack() {
      this.$router.back()
    }
  }
}
</script>
<style scoped lang="scss">
.upload-class {
  margin-bottom: 10px;
}
</style>
